.bg-dark {
  background-color: transparent !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #000000;
  font-family: Mulish;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.26px;
  padding: 25px 22px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #000000;
  text-decoration: none;
}
.navbar-dark .navbar-nav .nav-link:focus {
  color: #000000;
  text-decoration: none;
}
.navbar-dark .navbar-brand {
  color: black;
}
.navbar-dark .navbar-brand:hover {
  color: black;
}
.navbar-nav .sign-i .light-shadow {
  background-color: #0fb918;
  color: #ffffff;
  border: 2px solid #0fb918;
  width: 152px;
  margin-right: 20%;
  border-radius: 10px;
  font-family: Mulish;
  font-weight: 900;
  font-size: 14px;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: black;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.score {
  font-family: Mulish;
  font-weight: 700;
  font-size: 48px;
  line-height: 62.4px;
  text-align: center;
  margin: 10% auto 5%;
}
.score p {
  font-family: Mulish;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  text-align: center;
  margin-top: 20px;
}
.simple {
  float: right;
  text-align: right;
  margin-top: 15%;
  font-family: Mulish;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.simple p {
  font-family: Mulish;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}
.app-gog img {
  margin-bottom: 30px !important;
}
.undo {
  float: left;
  text-align: left;
  margin-top: 15%;
  font-family: Mulish;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.undo p {
  font-family: Mulish;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}
.app-n {
  text-align: center;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  padding-left: 9%;
}
.app-n p {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.big-n {
  font-family: Mulish;
  font-weight: 700;
  font-size: 48px;
  line-height: 62.4px;
}
.big-n p {
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  line-height: 25.6px;
  margin-top: 2%;
}
.one img {
  margin-top: -26%;
  position: absolute;
  width: 15%;
  /* margin-left: 63%; */
}
.two img {
  margin-top: -25%;
  position: absolute;
  width: 15%;
  margin-right: 5%;
}
.three img {
  margin-top: -25%;
  position: absolute;
  width: 15%;
  /* margin-left: 63%; */
}
.four img {
  margin-top: -23%;
  position: absolute;
  width: 15%;
  margin-right: 5%;
}
.five img {
  margin-top: -24%;
  position: absolute;
  width: 15%;
  /* margin-left: 63%; */
}

.one-s img {
  width: 100%;
  height: 40vh;
  object-fit: contain;
}
.multipl {
  font-family: Mulish;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-top: 10%;
  width: 100%;
  height: 40vh;
  background: #fff0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.multipl p {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}
.mrg-tp {
  margin-top: 5%;
  margin-bottom: 10%;
}
.brand {
  font-family: Mulish;
  font-weight: 700;
  font-size: 48px;
  line-height: 62.4px;
  margin-top: 25%;
  color: #ffffff;
}
.brand p {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #ffffff;
  margin-top: 4%;
}
.brand label {
  font-family: Mulish;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  margin-top: 6%;
}
.now-ap {
  margin-top: 5%;
}
.desk img {
  width: 100%;
  margin-top: 30%;
  margin-bottom: -10%;
}
.lapt img {
  width: 60%;
  margin-top: -25%;
  margin-left: 40%;
  position: absolute;
  z-index: 2;
}
.tab img {
  width: 19%;
  margin-top: -20%;
  margin-left: 77%;
  position: absolute;
  z-index: 2;
}
.mobile img {
  width: 33%;
  margin-top: -27%;
  margin-left: -5%;
  position: absolute;
  z-index: 2;
}
.iphonx img {
  width: 17.5%;
  margin-top: -14%;
  margin-left: 14%;
  position: absolute;
  z-index: 2;
}
.graph img {
  width: 100%;
  margin-bottom: 0;
}
.gear-s {
  margin-top: 15%;
  font-family: Mulish;
  font-weight: 700;
  font-size: 48px;
  line-height: 62.4px;
}
.gradient-c {
  margin-top: 5%;
}
.gear-s p {
  margin-top: 5%;
  font-family: Mulish;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
}
.go-btn .light-shadow {
  text-align: center;
  background-color: #0fb918;
  color: #ffffff;
  border: 2px solid #48e31e;
  width: 184px;
  border-radius: 10px;
  font-family: Mulish;
  font-weight: 900;
  font-size: 14px;
}
/* .ggg {
        background-color: lightblue;
    } */
.featr {
  display: block;
  margin-top: 35%;
  font-family: Mulish;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.fea img {
  width: 45px;
  margin: 40% 1rem 0;
}

@media screen and (min-width: 1000px) {
  .fea img {
    margin-right: 0;
  }
}

.heigth {
  font-family: Mulish;
  font-weight: 600;
  font-size: 14px;
  line-height: 22.4px;
}
.all-s {
  font-family: Mulish;
  font-weight: 400;
  font-size: 14px;
  line-height: 22.4px;
  color: #8d8d9a;
}
.smart {
  margin: 3% 0 3%;
}
.instra img {
  margin-bottom: -40%;
  margin-left: -40%;
}
.graphi {
  margin-top: 5%;
}

@media screen and (min-width: 1000px) {
  .graphi {
    margin-left: 15px;
  }
}

.cel-phns {
  display: none;
}
.carousel-cont {
  display: none;
}
@media screen and (max-width: 1560px) {
  .cel-phn img {
    width: 100%;
  }
  .undos {
    margin-top: 5%;
  }
  .und {
    margin-top: 9%;
  }
  .instra img {
    margin-bottom: -16%;
    margin-left: -48%;
  }
  .pro-s {
    padding: 4%;
  }
  .save-s {
    padding: 8%;
  }
}
@media screen and (max-width: 415px) {
  .navbar-toggler {
    margin-left: 86%;
    margin-top: -10%;
  }
  .nvbr-brnd img {
    width: 35%;
    height: auto;
  }
  .graphi {
    margin-bottom: 5%;
    padding-bottom: 5%;
    border-bottom: 2px solid rgba(232, 232, 232, 1);
  }
  .heigth {
    margin: 6% 0;
  }
  .score {
    font-size: 32px;
    margin-top: 35%;
  }
  .big-n {
    font-size: 32px;
  }
  .multipl {
    font-size: 20px;
    text-align: center;
    height: auto;
  }
  .one-s img {
    width: 100%;
  }
  .one img {
    margin-left: -78%;
    margin-top: -36% !important;
    position: absolute;
    width: 15%;
    /* margin-right: 30%; */
  }
  .two img {
    margin-left: 96%;
    margin-top: -36% !important;
    position: absolute;
    width: 15%;
    margin-right: 30%;
  }
  .three img {
    margin-left: -78%;
    margin-top: -36% !important;
    position: absolute;
    width: 15%;
    /* margin-right: 30%; */
  }
  .four img {
    margin-left: 96%;
    margin-top: -36% !important;
    position: absolute;
    width: 15%;
    margin-right: 30%;
  }
  .five img {
    margin-left: -78%;
    margin-top: -36% !important;
    position: absolute;
    width: 15%;
    /* margin-right: 30%; */
  }
  .brand {
    font-size: 32px;
    text-align: center;
  }
  .app-gog img {
    margin-bottom: 30px !important;
  }
  .c {
    text-align: center;
    margin-top: 3%;
  }
  .desk img {
    margin-left: 10%;
  }
  .mobile img {
    margin-left: -2%;
  }
  .gear-s {
    font-size: 32px;
    text-align: center;
  }
  .graph img {
    margin-top: 15%;
    border-bottom: 3px solid #0fb918;
  }
  .go-btn .light-shadow {
    text-align: center;
    justify-content: center;
    margin-left: 25%;
  }
  .instra img {
    width: 100%;
    margin-left: 0%;
  }
  .featr {
    text-align: center;
    margin-top: 10%;
  }
  .all-s {
    text-align: center;
    margin: 10% 0;
  }
  .ggg {
    background-color: white;
  }
  .backgg {
    background: none !important;
  }
  .understand-s {
    display: none !important;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: visible;
  }
  .carousel-caption {
    color: black;
    text-align: center;
  }
  .car-ousels {
    margin-top: 45%;
  }
  .carousel-indicators li {
    background-color: #c1c1c1;
  }
  .undo {
    text-align: center;
  }
  .simple {
    text-align: center;
  }
  .cel-phns {
    display: block;
  }
  .carousel-cont {
    display: block;
  }
}
@media screen and (max-width: 360px) {
  .car-ousels {
    margin-top: 65%;
  }
  .cel-phns img {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .car-ousels {
    margin-top: 24rem;
  }
}

/*  */
.logo {
  height: 50px;
}

.lab {
  height: 36px;
}

.nav-separetor {
  display: inline-block;
  margin: 0px 10px;
  width: 2px;
  height: 50px;
  background-color: #c4c4c4 !important;
  margin-bottom: -20px;
}

.buildSec {
  width: calc(100% + 30px);
  /* background-color: #000; */
  margin-left: -15px;
  padding: 5% 15px;
}

.mb--5 {
  position: absolute;
  margin-top: -70px;
}

@media only screen and (max-width: 1280px) {
  .mb--5 {
    position: absolute;
    margin-top: -80px;
  }
}

@media only screen and (max-width: 600px) {
  .buildSec {
    width: calc(100% + 35px);
    /* background-color: #000; */
    margin-left: -15px;
    padding: 10% 15px;
  }
  .mb--5 {
    position: relative;
    margin-top: -60px;
  }
}

.navbar-toggler {
  background-color: #fff0;
}

.menu {
  width: 25px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
}

.menu-bar {
  width: 100%;
  height: 2px;
  background-color: #000;
}

.modal-dialog-centered .modal-body {
  border-radius: 10px;
}

/* .menu-bar:nth-child(2) {
  width: 80%;
} */
