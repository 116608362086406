*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  width: 100%;
  overflow-x: hidden;
}
body {
  font-family: "Ubuntu", sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -0.03em;
  width: 100%;
  overflow-x: hidden;
  font-size: 1.6rem;
}

.scroll,
.scroll-f {
  color: #000;
}

.nav-scroll {
  padding: 38px 0;
  margin-right: 30px;
}

@media only screen and (max-width: 450px) {
  .nav-scroll {
    padding: 15px 0;
  }
}

.scroll:hover,
.scroll-f:hover {
  color: #000;
  text-decoration: none;
}

.backg {
  width: 100%;
  height: 100%;
  margin-top: -35px;
}
@media only screen and (max-width: 450px) {
  .backg {
    margin-top: 10px;
  }
}
.layer {
  width: 100%;
  height: 100%;
}
.big-n {
  margin-top: 7%;
  text-align: center;
}
.news-b .light-shadow {
  background-color: #e93030;
  color: #ffffff;
  border: 2px solid #e93030;
  width: auto;
  border-radius: 10px;
  font-family: Mulish;
  font-weight: 900;
  font-size: 14px;
  margin-right: 10px;
}
.news-b span {
  font-family: Mulish;
  font-weight: 700;
  font-size: 32px;
  color: #055985;
}
.graph {
  font-family: Mulish;
  font-weight: 700;
  font-size: 70px;
  color: #000000;
  text-align: center;
}
.solution {
  font-family: Mulish;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  text-align: center;
}
.demo-s {
  margin-top: 5%;
}
.d-flex .light-shadow {
  text-align: center;
  background-color: #48e31e;
  color: #ffffff;
  border: 2px solid #48e31e;
  width: 184px;
  border-radius: 10px;
  font-family: Mulish;
  font-weight: 900;
  font-size: 14px;
}
.feature .light-shadow {
  text-align: center;
  background-color: #ffffff;
  color: #48e31e;
  border: 2px solid #48e31e;
  width: auto;
  border-radius: 10px;
  font-family: Mulish;
  font-weight: 900;
  font-size: 14px;
}
.vdeo-s {
  margin: 5% auto 5%;
}
/* .iframe {
} */
.easy-s {
  padding: 4% 10%;
  background-color: #ffffff;
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%); */
  /* background: linear-gradient(315deg, #0FB918 0%, #48E31E 100%); */

  /* border: 1px solid #48e31e; */
  font-size: 20px;
  font-weight: 700;
  font-family: Mulish;
  color: #000000;
  margin-top: 6%;
  height: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 0px;
}

.easy-s p {
  font-size: 16px;
  font-weight: 400;
  font-family: Mulish;
  margin-top: 2%;
  line-height: 25.6px;
}

.super-s {
  padding: 4% 10%;
  background-color: #ffffff;
  /* border: linear-gradient(135deg, #5EBECC 0%, #3B87FA 100%); */
  /* border: 1px solid #3b87fa; */
  font-size: 20px;
  font-weight: 700;
  font-family: Mulish;
  color: #000000;
  margin-top: 6%;
  height: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 0px;
}

.super-s p {
  font-size: 16px;
  font-weight: 400;
  font-family: Mulish;
  margin-top: 2%;
  line-height: 25.6px;
}
.pro-s {
  padding: 4% 10%;
  background-color: #ffffff;
  /* border: 1px solid rgba(233, 48, 48, 1); */
  font-size: 20px;
  font-weight: 700;
  font-family: Mulish;
  color: #000000;
  margin-top: 6%;
  height: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 0px;
}

.pro-s p {
  font-size: 16px;
  font-weight: 400;
  font-family: Mulish;
  margin-top: 2%;
  line-height: 25.6px;
}
.cloud-s {
  padding: 4% 10%;
  background-color: #ffffff;
  /* border: 1px solid #f46043; */
  font-size: 20px;
  font-weight: 700;
  font-family: Mulish;
  color: #000000;
  margin-top: 6%;
  height: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 0px;
}
.cloud-s p {
  font-size: 16px;
  font-weight: 400;
  font-family: Mulish;
  margin-top: 2%;
  line-height: 25.6px;
}
.xml-s {
  padding: 4% 10%;
  background-color: #ffffff;
  /* border: 1px solid #3144d9; */
  font-size: 20px;
  font-weight: 700;
  font-family: Mulish;
  color: #000000;
  margin-top: 6%;
  height: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 0px;
}
.xml-s p {
  font-size: 16px;
  font-weight: 400;
  font-family: Mulish;
  margin-top: 2%;
  line-height: 25.6px;
}
.save-s {
  padding: 4% 10%;
  background-color: #ffffff;
  /* border: 1px solid #d500bf; */
  font-size: 20px;
  font-weight: 700;
  font-family: Mulish;
  color: #000000;
  margin-top: 6%;
  height: 198px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 0px;
}
.save-s p {
  font-size: 16px;
  font-weight: 400;
  font-family: Mulish;
  margin-top: 2%;
  line-height: 25.6px;
}
.diff-img img {
  margin-top: -31%;
  margin-left: -5%;
  position: absolute;
}

@media only screen and (min-width: 1441px) {
  .diff-img img {
    margin-top: -25%;
    margin-left: -5%;
    position: absolute;
  }
}
.crds-pro {
  margin-bottom: 5%;
}
.most {
  margin-top: 10%;
  text-align: center;
}
.trsted p {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  font-family: Mulish;
  margin-top: 15%;
  margin-bottom: 10%;
  line-height: 48px;
}
.cricket-acad img {
  margin-left: 1%;
  margin-top: 0%;
  margin-bottom: 10%;
}
@media screen and (max-width: 1560px) {
  .cricket-acad img {
    margin-left: 1%;
    margin-top: 0%;
    margin-bottom: 10%;
    width: 13%;
    height: 95px;
    /* width: 110px; */
    object-fit: contain;
  }
  .diff-img img {
    margin-top: -31%;
    margin-left: -6%;
    position: absolute;
  }
  /* .pro-s {
    padding: 4%;
  }
  .pro-s p {
    margin-left: 5%;
  }
  .save-s {
    padding: 5.1%;
  }
  .save-s p {
    margin-left: 5%;
  } */
}

@media screen and (max-width: 450px) {
  .cricket-acad {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .cricket-acad img {
    width: 80px;
  }
}

@media screen and (max-width: 415px) {
  .news-b .light-shadow {
    width: auto;
    display: block;
    margin: auto;
  }
  .news-b span {
    font-size: 20px;
  }
  .graph {
    font-size: 48px;
  }
  .justify-content-end {
    justify-content: center !important;
  }
  .justify-content-start {
    justify-content: center !important;
  }
  .d-flex .light-shadow {
    margin-bottom: 3%;
  }
  .diff-img img {
    margin-top: -31%;
    margin-left: -1%;
    position: absolute;
  }

  .easy-s {
    width: 90%;
    margin-left: 5%;
    padding: 4% 10%;
  }
  .super-s {
    width: 90%;
    margin-left: 5%;
    padding: 4% 10%;
  }
  .pro-s {
    width: 90%;
    margin-left: 5%;
    padding: 4% 10%;
  }
  .cloud-s {
    width: 90%;
    margin-left: 5%;
    padding: 4% 10%;
  }
  .xml-s {
    width: 90%;
    margin-left: 5%;
    padding: 4% 10%;
  }
  .save-s {
    width: 90%;
    margin-left: 5%;
    padding: 4% 10%;
  }
  .layer {
    background: none !important;
  }
}

/*  */

.mainBtn {
  background-image: linear-gradient(315deg, #0fb918 0%, #48e31e 100%);
  padding: 15px 30px;
  border: none !important;
  outline: none !important;
  font-size: 14px !important;
  font-weight: 900 !important;
}

.btn {
  padding: 15px 30px;
  outline: none !important;
  font-size: 14px !important;
  font-weight: 900 !important;
}
.header-red {
  padding: 6px 15px !important;
  background-image: linear-gradient(135deg, #ff7a7a 0%, #e93030 100%);
  border-radius: 10px !important;
  border: none !important;
  outline: none !important;
}
.iframe {
  /* width: 80%;
  height: 550px;
  border-radius: 10px;
  margin-bottom: 5%;
  border: none;
  position: relative; */
  overflow: "hidden";
  overflow-x: "hidden";
  overflow-y: "hidden";
  height: "100%";
  width: "100%";
  position: "absolute";
  top: "0px";
  left: "0px";
  right: "0px";
  bottom: "0px";
}

@media only screen and (min-width: 1441px) {
  .iframe {
    width: 80%;
    height: 750px;
    border-radius: 10px;
    margin-bottom: 5%;
    border: none;
    position: relative;
  }
}

.iframe-after {
  display: block;
  border-radius: 10px;
  width: 80%;
  height: 550px;
  background: #fff;
  position: absolute;
  bottom: 15px;
  right: -30px;
  box-shadow: 4px 4px 36px 4px rgb(5 89 133 / 10%);
}

@media only screen and (min-width: 1441px) {
  .iframe-after {
    display: block;
    border-radius: 10px;
    width: 80%;
    height: 750px;
    background: #fff;
    position: absolute;
    bottom: 15px;
    right: -30px;
    box-shadow: 4px 4px 36px 4px rgb(5 89 133 / 10%);
  }
}

@media screen and (max-width: 415px) {
  .iframe {
    width: 100%;
    height: 250px;
  }

  .iframe-after {
    display: block;
    border-radius: 10px;
    width: 90%;
    height: 250px;
    background: #fff;
    position: absolute;
    bottom: -5px;
    right: -5px;
    box-shadow: 4px 4px 36px 4px rgb(5 89 133 / 10%);
  }
}

.pos-relative {
  position: relative;
}

.PhoneInputInput {
  border: 0;
  background: transparent;
}

.PhoneInputInput:focus {
  border: none !important;
  background-color: #ffffff !important;
}
.PhoneInputInput:focus {
  outline: none !important;
}

.crickslabVideo{
  aspect-ratio: 16 / 9; 
  position:relative; 
  display:block; 
  width: 100%; 
  max-width: 800px;
}

.videoIframe{
  position:absolute; 
  top:0;
  left: 0; 
  background-color: white;
  border-radius: 3rem;
}

.ContactModal .modal-content  {
  border-radius: 1.3rem;
}
